import { createIdSlug, createNewCompanyIdSlug } from './common'

export const LINK_URL_JOBS = '/jobs'
export const LINK_URL_FORGOT_PASSWORD = '/forgot-password'
export const LINK_URL_UPDATE_PASSWORD = '/update-password'
export const LINK_URL_REGISTER = '/register'
export const LINK_URL_LOGIN = '/login'
export const LINK_URL_ROOT = '/'
export const LINK_URL_BLOG_CATEGORY = 'https://blog.freec.asia/category/nha-tuyen-dung/'
export const LINK_URL_JOB_CATEGORY = (id: number): string => `/jobs?job_category[]=${id}`

export const LINK_URL_CANDIDATE = `${process.env.NEXT_PUBLIC_APP_DOMAIN}`
export const LINK_URL_JOB_PUBLIC = `${process.env.NEXT_PUBLIC_APP_DOMAIN}/jobs`
export const LINK_URL_DOWNLOAD_APPLE = 'https://apps.apple.com/us/app/freec/id1475879992?l=vi&ls=1'
export const LINK_URL_DOWNLOAD_ANDROID = 'https://play.google.com/store/apps/details?id=com.freec.asia'

export const LINK_URL_COMPANY_CANDIDATES = (companyID: number): string => `/${companyID}/candidates`
export const LINK_URL_COMPANY_SEARCH_CV = (companyID: number): string => `/${companyID}/search-cv`
export const LINK_URL_COMPANY_SEARCH_CV_DETAIL = (companyID: number, profileId: number): string => `/${companyID}/search-cv/${profileId}`
export const LINK_KEY_COMPANY_CANDIDATES = `/[companyID]/candidates`
export const LINK_URL_COMPANY_CLIENT = '/client'
export const LINK_URL_COMPANY_CREATE_JOB = (companyID: number): string => `/${companyID}/jobs/create`
export const LINK_URL_COMPANY_CREATE_JOB_FIRST_TIME = (companyID: number): string => `/${companyID}/jobs/create?first_time=true`
export const LINK_KEY_COMPANY_CREATE_JOB = `/[companyID]/jobs/create`
export const LINK_URL_COMPANY_CREATE_JOB_FOR_CLIENT = '/jobs/create-job-for-client'
export const LINK_URL_COMPANY_JOB_LIST = (companyID: number): string => `/${companyID}/jobs`
export const LINK_KEY_COMPANY_JOB_LIST = `/[companyID]/jobs`
export const LINK_URL_COMPANY_EDIT_JOB = (companyID: number, id: number | string): string => `${LINK_URL_COMPANY_JOB_LIST(companyID)}/${id}`
export const LINK_KEY_COMPANY_EDIT_JOB = `${LINK_KEY_COMPANY_JOB_LIST}/[id]`
export const LINK_URL_COMPANY_JOB_DETAIL = (companyID: number, id: number | string | any): string =>
  `${LINK_URL_COMPANY_JOB_LIST(companyID)}/${id}/detail`

export const LINK_KEY_COMPANY_JOB_DETAIL = `${LINK_KEY_COMPANY_JOB_LIST}/[id]/detail`
export const LINK_URL_COMPANY_JOB_PREVIEW = (companyID: number, id: number | string): string => `/${companyID}/${id}/preview`
export const LINK_KEY_COMPANY_JOB_PREVIEW = `/[companyID]/[jobID]/preview`
export const LINK_URL_LP_HEADHUNT_SERVICE = `/headhunt-service`
export const LINK_URL_WAIT_VERIFICATION = '/register/wait-for-verification'
export const LINK_URL_INVITE_MEMBER = '/account/invite-team-members'
export const LINK_URL_EMAIL_TEMPLATES = '/account/email-templates'

export const LINK_URL_EMPLOYER_PRODUCT = '/'
export const LINK_URL_EMPLOYER_PRODUCT_SERVICE_PLANS = '/product#service-plans'
export const LINK_URL_EMPLOYER_CLIENT_CREATE = '/client/create-client'
export const LINK_URL_EMPLOYER_CLIENT_ADD_CLIENT = '/client/add-client'
export const LINK_URL_EMPLOYER_CONTACT = `/contact`
export const LINK_URL_CHAT_WITH_SUPPORT = `${process.env.NEXT_PUBLIC_APP_DOMAIN}/chat-with-support`
export const LINK_URL_HELP_CENTER = `https://support.freec.asia/vi/`

export const LINK_URL_SUPPORT = `https://support.freec.asia/vi/`
export const LINK_URL_TERMS = `https://support.freec.asia/vi/article/dieu-khoan-va-dieu-kien-freecasia-2suocb/?bust=1620617563164`
export const LINK_URL_POLICY = `https://support.freec.asia/vi/article/quy-tac-bao-mat-freecasia-17jjmfq/`

export const LINK_URL_EMAIL_SENT = '/forgot-password/confirmation'
export const LINK_URL_FORGOT_VERIFY_TOKEN = '/forgot-password/reset-password'

export const LINK_URL_ABOUT_FREEC = 'https://freecracy.com/'
export const LINK_URL_CANDIDATES_PLATFORM = 'https://freec.asia/'
export const LINK_URL_LINKIN = 'https://www.linkedin.com/company/freecasia/'
export const LINK_URL_FACEBOOK = 'https://www.facebook.com/freecasiacorp/'
export const LINK_URL_YOUTUBE = 'https://www.youtube.com/channel/UCIb1ZELs5mI5I2T5xTIB-zQ'
export const LINK_JOB_POST_POLICY =
  process.env.NEXT_PUBLIC_LINK_JOB_POST_POLICY || 'https://support.freec.asia/vi/article/quy-dinh-dang-tin-1kpnrdd/?bust=1621588019101'
export const LINK_URL_INSTAGRAM = 'https://www.instagram.com/freec_vn/'

export const LINK_URL_MY_ACCOUNT = '/account/your-account'
export const LINK_URL_VERIFY_MEMBER_UPDATE_PROFILE_INFOR = '/verify-member/update-member-infor'
export const LINK_URL_TOKEN_NOT_FOUND = '/token-not-found'
export const LINK_URL_CANDIDATE_ONBOARDING = '/onboarding'
export const LINK_URL_CANDIDATE_REGISTER = '/register'
export const LINK_URL_BLOG = '/blogs'
export const LINK_URL_MY_JOBS = '/my-jobs'
export const LINK_URL_CANDIDATES_MY_PROFILE = '/my-profile'
export const LINK_URL_CANDIDATES_PUBLISH_PROFILE = '/profiles/[...slugName]'
export const LINK_URL_COMPANY_PUBLISH_JOBS = '/company/[slug]/jobs'
export const LINK_URL_COMPANY_PUBLISH_JOBS_SLUG = (slug: string, id: string | number) =>
  `${process.env.NEXT_PUBLIC_APP_DOMAIN}/company/${createNewCompanyIdSlug(slug, id)}`
export const LINK_URL_COMPANY_PUBLISH_JOBS_SLUG_VN = (slug: string, id: string | number) =>
  `${process.env.NEXT_PUBLIC_APP_DOMAIN}/cong-ty/${createNewCompanyIdSlug(slug, id)}`
export const LINK_URL_CANDIDATES_MANAGE_YOUR_CV = '/account/manage-cv'
export const LINK_URL_CANDIDATES_JOB_REFERENCE = '/job-preference'
export const LINK_URL_CANDIDATES_CHANGE_PASSWORD = '/account/change-password'
export const LINK_URL_EMPLOYER_REGISTER = '/register'
export const LINK_URL_ACCOUNT_COMPANY = '/account/company-account'
export const LINK_URL_RECRUITER_RECREATE_JOBS = (comapyid: number, jobId: number | string): [string, string] => [
  `/[companyID]/jobs/create?from-job-id=${jobId}`,
  `/${comapyid}/jobs/create?from-job-id=${jobId}`
]
export const LINK_URL_COMPANY_DETAIL = (slug: string) => `/company/${slug}/jobs`

export const LINK_URL_CONTACT_SUCCESS = '/contact/success'

export const LINK_URL_ONBOARDING_CANDIDATE_STEP_1 = '/onboarding/step1'
export const LINK_URL_ONBOARDING_CANDIDATE_STEP_2 = '/onboarding/step2'
export const LINK_URL_EMPLOYER_VIEW_APPLICANT = (
  id: number | string | string[],
  jobID: number | string,
  candidatesID: number | string
): string => `/${id}/jobs/${jobID}/applicants/${candidatesID}`
export const LINK_KEY_EMPLOYER_VIEW_APPLICANT = '/[companyID/jobs/[id]/applicants/[applicantId]'
export const LINK_VIEW_SHARE_PROFILE = '/candidates/share/[token]'
export const LINK_URL_ABOUT_404 = '/404'
export const LINK_URL_JOB_PUBLISH = (slug: string, id: number): string => `/jobs/${createIdSlug(slug, id)}`
export const LINK_URL_COMPANIES = '/companies'
export const LINK_URL_COMPANIES_WITH_LOCATION = (locationId: string | number) => `/companies?location=${locationId}`

export const LINK_URL_QUICK_APPLY = '/quick-apply'

export const LINK_URL_COMPANY_PUBLIC_COMPANY = (slug: string) => `${process.env.NEXT_PUBLIC_APP_DOMAIN}/company/${slug}`
export const LINK_URL_DOWNLOAD_APP = '/download-app'
export const LINK_URL_JOB_DETAILS = (slug: string, id: number | string) =>
  `${process.env.NEXT_PUBLIC_APP_DOMAIN}/jobs/[slug]`.replace('[slug]', createIdSlug(slug, id))

export const LINK_URL_CONSULTANT_INVITE_MEMBER = `/account/invite-team-members`
