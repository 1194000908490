import FreecProvider from 'src/@legacy/@core/components/FreecProvider'
import { useNProgress } from 'src/@legacy/@core/hooks/NProgress'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'
import createI18n from 'src/locales/i18n'
import { DataProvider } from 'src/@legacy/store/store'
import { getIsServer } from 'src/@legacy/utilities/common'
import { COOKIES_NAME_LANGUAGE, DEFAULT_LOCALE } from 'src/@legacy/utilities/Constant'
import { getCookie } from 'src/@legacy/utilities/Cookies'
import { LayoutProvider } from '../DefaultLayout'
import * as Mixpanel from '../../utilities/Mixpanel'
Mixpanel.init()
const withAppProvider = (Page, { loadOnClient = true }: { loadOnClient: boolean } = { loadOnClient: true }) => {
  const FreecApp = ({ serverVar, serverGetCookie, serverBundle, ...pageProps }) => {
    const realLocale = (getIsServer() ? DEFAULT_LOCALE : getCookie(COOKIES_NAME_LANGUAGE)) || DEFAULT_LOCALE
    createI18n(realLocale)
    const router = useRouter()
    const [clientRender, setClientRender] = useState(!loadOnClient)
    useEffect(() => {
      if (router.isReady || router.pathname == '/404') setClientRender(true)
    }, [router.isReady])

    const serverService = useMemo(() => ({ originalUrl: router.asPath, getCookie: (name) => (getIsServer() ? '' : getCookie(name)) }), [])
    useNProgress()
    return (
      //@ts-ignore
      <FreecProvider serverBundle={serverBundle} serverService={serverService}>
        <DataProvider serverVar={serverVar} getCookie={getIsServer() ? serverGetCookie : getCookie}>
          <LayoutProvider>{clientRender ? <Page {...pageProps} /> : <div></div>}</LayoutProvider>
        </DataProvider>
      </FreecProvider>
    )
  }
  return FreecApp
}

export default withAppProvider
